<template>
    <div>
        <img :src="A84tQM" />
        <div class="media-all">
            <div class="media-title">
                <div :class="{'titleColor': mediaIndex == 0}" @click="mediaClick(0)">公司新闻</div>
                <div :class="{'titleColor': mediaIndex == 1}" @click="mediaClick(1)">媒体报道</div>
            </div>
            <div class="media-list">
                <div class="media-list-div" v-for="item in mediaList" :key="item.id">
                    <img class="media-list-div-img" :src="item.newsPicture" />
                    <div class="media-list-div-right">
                        <div class="media-list-div-right-tit" @click="$router.push({path: '/details', query:{id: item.id, type: 'new'}})">
                            <div class="media-list-div-right-tit-left">{{item.title}}</div>
                            <div>{{item.newsTime}}</div>
                        </div>
                        <div class="media-list-div-right-text">{{item.newsSynopsis}}</div>
                    </div>
                </div>
                <div class="media-paging" v-if="pageList.length > 1">
                    <div class="media-paging-up" v-if="params.pageNumber != 1" @click="pageClick(params.pageNumber - 1)">上一页</div>
                    <div class="media-paging-page">
                        <div v-for="item in pageList" :key="item" :class="{'media-paging-page-color': item == params.pageNumber}" @click="pageClick(item)">{{item}}</div>
                    </div>
                    <div class="media-paging-down" v-if="params.pageNumber != pageList.length" @click="pageClick(params.pageNumber + 1)">下一页</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { A84tQM } from '@/utils/imgUrl'
import { newsPaging } from '@/api'
export default {
    name: 'media',
    data(){
        return{
            mediaIndex: 0,
            params: {
                condition: {
                    content: '', //新闻内容
                    title: '', //新闻标题
                    newsMark: 0, //新闻类型
                    newsSource: '', //新闻摘要
                    newsWriter: '', //新闻作者
                    startTime: '',
                    endTime: '',
                    sort: 'DESC',//ASC升序 DESC 降序
                    sortColumn: 'news_time',
                    time: []
                },
                pageNumber: 1,
                pageSize: 5
            },
            mediaList: [],
            pageList: []
        }
    },
    computed:{
        A84tQM: () => A84tQM,
    },
    created(){
        this.getList()
    },
    methods:{
        getList(){
            this.pageList = []
            newsPaging(this.params).then(res => {
                console.log(res)
                this.mediaList = res.records
                for (let index = 1; index <= res.pages; index++) {
                    this.pageList.push(index)
                }
                console.log(this.pageList)
            })
        },
        mediaClick(index){
            this.mediaIndex = index
            this.params.condition.newsMark = index
            this.getList()
        },
        pageClick(index){
            this.params.pageNumber = index
            this.getList()
        }
    }
}
</script>
<style lang="scss" scoped>
.media-all{
    width: 1300px;
    margin: auto;
}
.media-title{
    width: 100%;
    height: 65px;
    background: #0ab5a3;
    color: #60e7d8;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 35%;
    box-sizing: border-box;
    div{
        cursor: pointer;
    }
}
.titleColor{
    color: #fff;
}
.media-list{
    margin: 30px 0;
}
.media-list-div{
    display: flex;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px dashed #e5e5e5;
}
.media-list-div:last-child{
    border: none;
}
.media-list-div-img{
    width: 80px;
    height: 80px;
    margin-right: 10px;
}
.media-list-div-right{
    flex: 1;
}
.media-list-div-right-tit{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    font-size: 14px;
    cursor: pointer;
}
.media-list-div-right-tit-left{
    font-weight: bold;
}
.media-list-div-right-tit:hover{
    color: #079deb;
}
.media-list-div-right-text{
    font-size: 12px;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
}
.media-paging{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    div{
        cursor: pointer;
        margin-right: 35px;
    }
}
.media-paging-page{
    display: flex;
}
.media-paging-page-color{
    color: #079deb;
}
.media-paging-page div:last-child{
    margin-right: 0 !important;
}
.media-paging-down{
    margin: 0 !important;
}
</style>